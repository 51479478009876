<template>
  <div class="wrap">
    <div class="The_header">
      <ul class="bg-fff">
        <li class="left">
          <router-link to="/profile">
            <i class="iconfont icon-fanhui"></i>
          </router-link>
        </li>
        <li>{{ t("common.title.siteMessage") }}</li>
        <li class="right">
          <a @click="readAllSiteMessages">
          </a>
        </li>
      </ul>
    </div>
    <BetterScroll
      ref="scrollRef"
      @pullingUp="fetchMorePage"
      v-if="alreadyReq"
      class="BetterScroll"
    >
      <div class="main main_page">
        <div class="aoou_list">
          <div class="no_data" v-if="!listItems || listItems.length == 0">
            <p>{{ t("common.message.noRecords") }}</p>
          </div>
          <ol
            v-else
            :class="{ on: item.status !== 0, off: +item.status === 0 }"
            :key="index"
            v-for="(item, index) in listItems"
            @click="showMessage(item)"
          >
            <h3>{{ item.title }}</h3>
            <li>{{ formatServerDateTime(item.createDate) }}</li>
            <li>
              <b>{{ t("common.messageStatus.unread") }}</b>
              <b>{{ t("common.messageStatus.readed") }}</b>
            </li>
          </ol>
        </div>
      </div>
    </BetterScroll>
  </div>
  <AModal
    v-if="currentMsg"
    v-model:visible="visible"
    centered
    :footer="null"
    :title="t('common.title.siteMessage')"
    wrapClassName="Sys_Popup"
    width="85%"
    @cancel="readMessage(currentMsg)"
  >
    <div class="Sys_Popup_wrap Sys_Popup_aoou">
      <div class="top_name">
        <h2>{{ t("message.title", currentMsg) }}</h2>
        <h3>{{ formatServerDateTime(currentMsg.insertedAt) }}</h3>
      </div>
      <div class="box">
        {{ t("message.content", getParams(currentMsg)) }}
      </div>
    </div>
  </AModal>
</template>
<script>
import { ref, watch, onMounted, reactive } from "vue";
import BetterScroll from "@/components/BetterScroll.vue";
import { formatServerDateTime } from "@/utils/formatter";
import { useI18n } from "@/lang";
import api from "@/api";
export default {
  components: { BetterScroll },
  setup() {
    const { t } = useI18n();
    const scrollRef = ref(null);
    const visible = ref(false);
    const currentMsg = ref(null);
    const isStopReq = ref(false);
    const listItems = reactive([]);
    const currentPage = ref(0);
    const alreadyReq = ref(false);
    const fetchMorePage = () => {
      if (isStopReq.value) {
        return;
      }
      api
        .userMessage({
          current: currentPage.value + 1,
          size: 10,
        })
        .then((res) => {
          if (res?.data?.success) {
            alreadyReq.value = true;
            if (+res.data.data?.pages <= +currentPage.value + 1) {
              isStopReq.value = true;
            }
            currentPage.value += 1;
            listItems.push(...res.data.data?.records);
          }
        });
    };

    onMounted(() => {
      fetchMorePage();
    });

    watch(listItems, () => {
      if (scrollRef.value) {
        scrollRef.value.refresh();
      }
    });

    const showMessage = (msg) => {
      currentMsg.value = msg;
      visible.value = true;
    };
    const readMessage = (message) => {
      console.log(message);
    };
    const readAllSiteMessages = () => {};
    const getParams = (currentMsg) => {
      return JSON.stringify(currentMsg);
    };
    return {
      scrollRef,
      formatServerDateTime,
      listItems,
      fetchMorePage,
      currentMsg,
      showMessage,
      visible,
      readMessage,
      readAllSiteMessages,
      getParams,
      t,
      alreadyReq
    };
  },
};
</script>
<style lang="scss" scoped>
.wrap .main {
  position: relative;
  top: 0;
}
</style>
